import React from "react";
import Preloader from "../components/Preloader";

/**
 * Describes preloader state.
 */
export enum PreloaderState {
  /**
   * Means that neither long-term operation is in progress nor problem with network occured.
   */
  Hidden = 0,

  /**
   * Means that long-term operation is in progress.
   */
  InProgress = 1,

  /**
   * Means that a problem with network occured.
   */
  NetworkProblem = 2,
}

/**
 * State type for component base of RetailerApp aplication.
 */
export type MultistepComponentState = {
  /**
   * Defines preloader state.
   */
  preloaderState: PreloaderState,

  /**
   * Step number for multi-step forms.
   */
  step?: number,
};

/**
 * Component base of RetailerApp aplication.
 */
export default class MultistepComponent<P, S extends MultistepComponentState> extends React.Component<P, S> {
  private _step?: number;

  /**
   * Starts long-term operation and makes preloader visible.
   *
   * @param isNetworkProblem Defines
   */
  showPreloader(isNetworkProblem: boolean = false) {
    if (this.state.preloaderState === PreloaderState.Hidden) {
      this._step = this.state.step;
    }
    this.setState({
      preloaderState: isNetworkProblem ? PreloaderState.NetworkProblem : PreloaderState.InProgress,
      step: 0,
    });
  }

  /**
   * Ends long-term operation and makes preloader hidden.
   */
  hidePreloader() {
    if (this.state.preloaderState === PreloaderState.Hidden || this.state.step !== 0) {
      return;
    }
    this.setState({
      preloaderState: PreloaderState.Hidden,
      step: this._step,
    });
    delete this._step;
  }

  /**
   * Switches to the next page.
   *
   * @param stepCount Count of steps to skip.
   */
  next(stepCount: number = 1) {
    if (this._step !== undefined) {
      this._step += stepCount;
    }
    else {
      this.setState({ step: (this.state.step ?? 0) + parseInt(stepCount.toString()) });
    }
  }

  /**
   * Navigates to specified step.
   *
   * @param step Number of step to navigate.
   */
  navigate(step: number) {
    if (this._step !== undefined) {
      this._step = step;
    }
    else {
      this.setState({ step });
    }
  }

  /**
   * Switches to the previous page.
   *
   * @param stepCount Count of steps to skip.
   */
  back(stepCount: number = 1) {
    if (this._step !== undefined) {
      this._step -= stepCount;
    }
    else {
      this.setState({ step: (this.state.step ?? 0) - parseInt(stepCount.toString()) });
    }
  }

  /**
   * Renders preloader if needed.
   */
  renderPreloader() {
    if (this.state.preloaderState !== PreloaderState.Hidden) {
      return (
        <Preloader networkError={this.state.preloaderState === PreloaderState.NetworkProblem} />
      );
    }
  }
}
