import React, { PureComponent } from "react";
import loader from '../img/loader.gif';
import logo from "../svg/logo.svg";

/**
 * Fallback preloader component for react suspense.
 */
export default class FallbackPreloader extends PureComponent {
  render() {
    return (
      <>
        <div className="menu">
          <div className="logo">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="preloader">
          <img src={loader} alt="Loading..." />
        </div>
      </>
    );
  }
};
