import NetworkError from "./errors/NetworkError";
import ValidationError from "./errors/ValidationError";
import WebError from "./errors/WebError";

/**
 * Feedback form data for e-mail notification.
 */
type FriendsRentalFormData = {
  name: string,
  phone: string,
  friendsName: string,
  friendsPhone: string,
};

/**
 * Form data of godfather survey.
 */
type GetInTouchFormData = {
  form_url: string,
  name: string,
  phone: string,
};

/**
 * Form data of Smart Change form.
 */
type SmartChangeFormData = {
  name: string,
  phone: string,
  deviceModel: string,
  chargerCode: string,
  holderCode: string,
  deviceCode: string,
  assignTouchpoint?: boolean
};

/**
 * Form data of Warranty form.
 */
type WarrantyFormData = {
  name: string,
  phone: string
};

/**
 * Form data of Warranty form.
 */
type DeviceMaintenanceFormData = {
  name: string,
  phone: string
};

/**
 * Form data of Gifters form.
 */
type GiftersFormData = {
  name: string,
  phone: string,
  deviceModel: string,
  deviceOrComponent: string,
  chargerCode: string,
  holderCode: string,
  deviceCode: string,
};

export enum Model {
  Unset = -1,
  Iqos24Plus = 0,
  Iqos30 = 1,
  Iqos3Duo = 2,
  Lil = 3,
  IqosOriginals = 4,
  VeevOne = 5,
  IqosIluma = 6,
  IqosIlumaPrime = 7,
  IqosIlumaOne = 8,
}

export type SelfRegistrationFormData = {
  firstName: string,
  lastName: string,
  model: Model.Iqos24Plus | Model.Iqos3Duo | Model.IqosOriginals | Model.IqosIluma | Model.IqosIlumaPrime,
  holder: string,
  charger: string,
  phone: string,
  language: 'ka' | 'en',
} | {
  firstName: string,
  lastName: string,
  model: Model.Iqos30 | Model.Lil | Model.VeevOne | Model.IqosIlumaOne,
  holder: string,
  phone: string,
  language: 'ka' | 'en',
};

/**
 * Provides interaction with backend.
 */
export default class Api {

  /**
   * Sends survey notification.
   *
   * @param data Notification data for call center.
   *
   * @throws {WebError} Unsuccessful request.
   * @throws {NetworkError} An unexpected error.
   * @throws {ValidationError} Name or phone validation error.
   */
  static async sendFriendsRentalFormData(data: FriendsRentalFormData) {
    let response: Response;
    try {
      response = await fetch(`/api/v1/friends-rental-form`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    }
    catch (error) {
      throw new NetworkError();
    }

    switch (response.status) {
      case 200:
      case 201:
      case 202:
        // Do nothing
        return;

      case 400:
        let content = await response.json();
        throw new ValidationError(
          content.error_code,
          content.message
        );

      default:
        throw new WebError();
    }
  }

  /**
   * Sends survey notification.
   *
   * @param data Notification data for call center.
   *
   * @throws {WebError} Unsuccessful request.
   * @throws {NetworkError} An unexpected error.
   * @throws {ValidationError} Name or phone validation error.
   */
  static async sendGetInTouchFormData(data: GetInTouchFormData) {
    let response: Response;
    try {
      response = await fetch(`/api/v1/get-in-touch-form`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    }
    catch (error) {
      throw new NetworkError();
    }

    switch (response.status) {
      case 200:
      case 201:
      case 202:
        // Do nothing
        return;

      case 400:
        let content = await response.json();
        throw new ValidationError(
          content.error_code,
          content.message
        );

      default:
        throw new WebError();
    }
  }

  /**
   * Send SMART Change data.
   *
   * @param data Smart Change data for call center.
   *
   * @throws {WebError} Unsuccessful request.
   * @throws {NetworkError} An unexpected error.
   * @throws {ValidationError} Name or phone validation error.
   */
  static async sendSmartChangeFormData(data: SmartChangeFormData) {
    let response: Response;
    try {
      response = await fetch(`/api/v1/smart-change-form`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    }
    catch (error) {
      throw new NetworkError();
    }

    switch (response.status) {
      case 200:
      case 201:
      case 202:
        // Do nothing
        return;

      case 400:
        let content = await response.json();
        throw new ValidationError(
          content.error_code,
          content.message
        );

      default:
        throw new WebError();
    }
  }

  /**
   * Send Device Maintenance data.
   *
   * @param data Device Maintenance data for call center.
   *
   * @throws {WebError} Unsuccessful request.
   * @throws {NetworkError} An unexpected error.
   * @throws {ValidationError} Name or phone validation error.
   */
  static async sendDeviceMaintenanceFormData(data: DeviceMaintenanceFormData) {
    let response: Response;
    try {
      response = await fetch(`/api/v1/device-maintenance-form`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    }
    catch (error) {
      throw new NetworkError();
    }

    switch (response.status) {
      case 200:
      case 201:
      case 202:
        // Do nothing
        return;

      case 400:
        let content = await response.json();
        throw new ValidationError(
          content.error_code,
          content.message
        );

      default:
        throw new WebError();
    }
  }

  /**
   * Send Warranty data.
   *
   * @param data Warranty data for call center.
   *
   * @throws {WebError} Unsuccessful request.
   * @throws {NetworkError} An unexpected error.
   * @throws {ValidationError} Name or phone validation error.
   */
  static async sendWarrantyFormData(data: WarrantyFormData) {
    let response: Response;
    try {
      response = await fetch(`/api/v1/warranty-form`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    }
    catch (error) {
      throw new NetworkError();
    }

    switch (response.status) {
      case 200:
      case 201:
      case 202:
        // Do nothing
        return;

      case 400:
        let content = await response.json();
        throw new ValidationError(
          content.error_code,
          content.message
        );

      default:
        throw new WebError();
    }
  }

  /**
   * Send "Self Registration" form data.
   *
   * @param data "Self Registration" form data for call center.
   *
   * @throws {WebError} Unsuccessful request.
   * @throws {NetworkError} An unexpected error.
   * @throws {ValidationError} Name or phone validation error.
   */
  static async sendSelfRegistrationFormData(data: SelfRegistrationFormData) {
    let response: Response;
    try {
      response = await fetch(`/api/v1/self-registration`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    }
    catch (error) {
      throw new NetworkError();
    }

    switch (response.status) {
      case 200:
        // Do nothing
        return;

      case 400:
        let content = await response.json();
        throw new ValidationError(
          content.error_code,
          content.message
        );

      default:
        throw new WebError();
    }
  }

  /**
   * Send Gifters form data.
   *
   * @param data Gifters.
   *
   * @throws {WebError} Unsuccessful request.
   * @throws {NetworkError} An unexpected error.
   * @throws {ValidationError} Validation error.
   */
  static async sendGiftersFormData(data: GiftersFormData) {
    let response: Response;
    try {
      response = await fetch(`/api/v1/gifters`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
    }
    catch (error) {
      throw new NetworkError();
    }

    switch (response.status) {
      case 200:
      case 201:
      case 202:
        // Do nothing
        return;

      case 400:
        let content = await response.json();
        throw new ValidationError(
          content.error_code,
          content.message
        );

      default:
        throw new WebError();
    }
  }
}
