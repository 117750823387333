export default class ValidationError implements Error {
  name: string = 'ValidationError';
  errorCode: number;
  message: string;

  constructor(errorCode: number, message: string) {
    this.errorCode = errorCode;
    this.message = message;
  }
}
