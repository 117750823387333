import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { I18Namespace } from "../i18n";
import loader from '../img/loader.gif';

//#region ~Internal types~

/**
 * Properties type for Preloader component.
 */
type Props = WithTranslation & {
  networkError?: boolean;
};

//#endregion

/**
 * Preloader component.
 */
class Preloader extends Component<Props> {

  /**
   * Renders network error message if needed.
   */
  renderNetworkError() {
    if (this.props.networkError === true) {
      return (
        <p>{this.props.t('Preloader.networkError')}</p>
      );
    }
  }

  render() {
    return (
      <div className="preloader">
        <img src={loader} alt={this.props.t('Preloader.loading')} />
        {this.renderNetworkError()}
      </div>
    );
  }
}

// Init i18n wrapper.
export default withTranslation(I18Namespace.General)(Preloader);
