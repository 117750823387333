import { JSXElementConstructor, ReactElement } from "react";

/**
 * Conditionaly renders markup.
 *
 * @param condition
 *   Render condtition.
 * @param markupIfTrue
 *   Markup to be returned if render condition is __true__.
 * @param markupIfFalse
 *   Markup to be returned if render condition is __false__, or __undefined__ if nothing to be returned.
 *
 * @returns
 *   _markupIfTrue_ if render condition if __true__ or _markupIfFalse_ if is set one, otherwise __undefined__.
 */
export default function renderIf(condition: any, markupIfTrue: ReactElement, markupIfFalse: ReactElement | undefined = undefined): ReactElement | undefined {
  return condition ? markupIfTrue : markupIfFalse;
}

export function instanceOf<T>(className: JSXElementConstructor<T>, object: React.ReactNode): boolean {
  if (object !== null) {
    if (typeof object === 'object') {
      if (object.hasOwnProperty('type')) {
        return (object as { type: { name: string } }).type.name === className.name;
      }
    }
  }
  return false;
}