import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

/**
 * Namespaces of i18next.
 */
export enum I18Namespace {
  General = 'general',
  FriendsRental = 'friendsrental',
  GetInTouch = 'getintouch',
  GetInTouchI3DPurchase = 'getintouchi3dpurchase',
  SmartChange = 'smartchange',
  SelfRegistration = 'self-registration',
  Gifters = 'gifters',
  Warranty = 'warranty',
  LilWarrantyTransnistria = 'lwt',
  Rent = 'rent',
  DeviceMaintenance = 'devicemaintenance',
  IlumaPreorder = 'iluma-preorder',
  Anniversary = 'anniversary',
  SustainabilityForm = 'sustainability-form',
  SustainabilitySlider = 'sustainability-slider',
}

/**
 * Get language according "lang" GET parameter of current page.
 */
export function defaultLanguage(): string {
  let defaultLanguage = document.location.pathname.startsWith('/') ||
    document.location.pathname.startsWith('/get-in-touch-i3d-purchase-form') ||
    document.location.pathname.startsWith('/friends-rental-form') ? 'en' : 'ro';
  return new URLSearchParams(window.location.search).get('lang') ?? defaultLanguage;
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json'
    },
    lng: defaultLanguage(),
    fallbackLng: defaultLanguage(),
    debug: (process.env.NODE_ENV || 'development') === 'development',
    interpolation: {
      // Not needed for react as it escapes by default.
      escapeValue: false,
    },
    defaultNS: I18Namespace.General,
    ns: I18Namespace.General,
    load: 'currentOnly',
  });

export default i18n;
