import React, { Component } from "react";

//#region ~Internal types~

/**
 * Properties type for Finish component.
 */
type Props = {
  id: string,
  name?: string,
  placeholder?: string,
  value?: string,
  mask?: string,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

//#endregion

/**
 * Page with thanks message.
 */
export default class Input extends Component<Props> {

  //#region ~Event handlers~

  //#endregion

  onFocus(e: React.FocusEvent<HTMLInputElement>) {
    e.target.classList.add('nonempty');
    if (!e.target.value) {
      e.target.value = this.props.mask ?? '';
    }
  }

  onBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (!e.target.value) {
      e.target.classList.remove('nonempty');
    }
  }

  //#region ~Render methods~

  render() {
    return (
      <div>
        <input
          id={this.props.id}
          type="text"
          className={"input-field" + (this.props.value ? " nonempty" : "")}
          name={this.props.name}
          required={true}
          onFocus={this.onFocus.bind(this)}
          onBlur={this.onBlur.bind(this)}
          value={this.props.value}
          onChange={this.props.onChange}
        />
        <label htmlFor={this.props.id}>{this.props.placeholder}</label>
      </div>
    );
  }

  //#endregion

}