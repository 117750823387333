import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import FallbackPreloader from './components/FallbackPreloader';
import { GetInTouchURLs } from './components/GetInTouch/Form';
import reportWebVitals from './reportWebVitals';

const FriendsRental = lazy(() => import('./apps/FriendsRental/App'));
const GetInTouch = lazy(() => import('./apps/GetInTouch/App'));
const SmartChange = lazy(() => import('./apps/SmartChange/App'));
const SmartChangeTouchpoint = lazy(() => import('./apps/SmartChangeTouchpoint/App'));
const Gifters = lazy(() => import('./apps/Gifters/App'));
const SelfRegistration = lazy(() => import('./apps/SelfRegistration/App'));
const Warranty = lazy(() => import('./apps/Warranty/App'));
const LWT = lazy(() => import('./apps/Lwt/App'));
const DeviceRent = lazy(() => import('./apps/DeviceRent/App'));
const DeviceMaintenance = lazy(() => import('./apps/DeviceMaintenance/App'));
const IlumaPreorder = lazy(() => import('./apps/IlumaPreorder'));
const Anniversary = lazy(() => import('./apps/Anniversary10'));
const SustainabilityForm = lazy(() => import('./apps/SustainabilityForm'));
const SustainabilitySlider = lazy(() => import('./apps/SustainabilitySlider'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <Suspense fallback={<FallbackPreloader />}>
      <Routes>
        <Route path={GetInTouchURLs.I3DPurchase} element={<GetInTouch />} />
        <Route path="/smart-change-form" element={<SmartChange />} />
        <Route path="/smart-change-touchpoint-form" element={<SmartChangeTouchpoint />} />
        <Route path="/gifters-form" element={<Gifters />} />
        <Route path="/friends-rental-form" element={<FriendsRental />} />
        <Route path="/self-registration" element={<SelfRegistration />} />
        <Route path="/warranty-form" element={<Warranty />} />
        <Route path="/lwt" element={<LWT />} />
        <Route path="/rent-form" element={<DeviceRent />} />
        <Route path="/device-maintenance-form" element={<DeviceMaintenance />} />
        <Route path="/iluma-preorder" element={<IlumaPreorder />} />
        <Route path="/anniversary" element={<Anniversary />} />
        <Route path="/sustainability-form" element={<SustainabilityForm />} />
        <Route path="/sustainability-slider" element={<SustainabilitySlider />} />
        <Route path="/" element={<FriendsRental />} />
      </Routes>
    </Suspense>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
