import MultistepComponent, { MultistepComponentState } from "./MultistepComponent";

/**
 * Properties type for form base of RetailerApp aplication.
 */
export type FormProps<R> = {
  /**
   * Occurres when form is completed and submitted.
   */
  onSubmit?: (formResult: R) => void,

  /**
   * Occures on form exit.
   */
  onCancel?: () => void,
};

/**
 * State type for form base of RetailerApp aplication.
 */
export type FormState<T> = MultistepComponentState & {
  /**
   * Result of form.
   */
  formResult?: T,
};

/**
 * Form base of RetailerApp aplication.
 */
export default class Form<R, P extends FormProps<R>, S extends FormState<R>> extends MultistepComponent<P, S> {

  /**
   * Submits the form and raises "submit" event.
   *
   * @param formResult Form result.
   */
  submit(formResult: R) {
    if (this.props.onSubmit !== undefined) {
      this.props.onSubmit(formResult);
    }
  }

  /**
   * Cancel the form and raises "cancel" event.
   */
  cancel() {
    if (this.props.onCancel !== undefined) {
      this.props.onCancel();
    }
  }
}
